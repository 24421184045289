import { useEffect, useState } from "react";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { GLTFExporter } from "three/examples/jsm/exporters/GLTFExporter.js";

import useUploadFile from "./hooks/use_upload_file";
import useLoadGltf from "./hooks/use_load_gltf";
import downloadFile from "./util/download_file";
import getNewGltf from "./util/get_new_gltf";
import prepareExportFile from "./util/prepare_export_file";

function App() {

  const [isRender, setIsRender] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  
  const [isMaterialVariant, setIsMaterialVariant] = useState('')
  const style = { border: "1px solid black" };

  //HOOKS

  const {file, fileSize, handleChange} = useUploadFile()
  const { gltf, clips, xidList, textureList, allMaterials, setAllMaterials } = useLoadGltf(file)

  useEffect(()=>{
    console.log('allMaterials',allMaterials)
  }, [allMaterials])


  useEffect(() => {
    console.log("isRender", isRender);

    if (allMaterials) {
      const scene = new THREE.Scene();
      scene.add(gltf.scene);
      console.log(scene);
      setIsVisible(true);
    }
  }, [allMaterials]);

  function downloadHandler() {
    async function dwfunction() {
      const newGltf = await getNewGltf(allMaterials)
      console.log(allMaterials)
      for(let i = 0; i< allMaterials.length; i++) {
        if(allMaterials[i].isSeparate) {
          console.log('removing material', allMaterials[i])
          gltf.scene.traverse(function(child) {
            if(child.isMesh) {
              if(child.material.uuid === allMaterials[i].material.uuid) {
                const mat = new THREE.MeshStandardMaterial({color: '#fffff2'})
                child.material = mat
              }
            }
          })
        }
      }
      const blob = await prepareExportFile(gltf.scene, clips);
      await downloadFile(blob, file.name);
    }
    dwfunction();
  }

 function saveSeparatelyHandler(e) {
  const changedAllMaterials = allMaterials.map(item => {if(item.material.name === e.target.id) {
    return {type: item.type, variant: item.variant, isVariant: item.isVariant, material: item.material, isSeparate: e.target.checked}
  } else return item
})
setAllMaterials(changedAllMaterials)

 }
  
  return (
    <div>
      <form>
        <label for="file">Select File (.glb)</label>
        <p />
        <input id="file" type="file" accept = ".glb" onChange={handleChange} /> 
        <p />
        <label>Size : {fileSize}</label>
        <p />
       
      </form>
      <p />
      
      <table style={style}>
        <tr>
          <th style={style} width={"200px"}>
            NAME
          </th>
          <th style={style} width={"100px"}>
            TYPE
          </th>
          
          <th style={style} width={"300px"}>
            XID
          </th>
          <th style={style} width={"100px"}>
            MATERIAL
          </th>
          <th style={style} width={"300px"}>
            MATERIAL XID
          </th>
        </tr>
        {xidList &&
          xidList.map((item) => (
            <tr>
              <td style={style}>{item.name}</td>
              <td style={style}>{item.type}</td>
              <td style={style}>{item.xid}</td>
              <td style={style}>{item.materialName}</td>
              <td style={style}>{item.materialXid}</td>
            </tr>
          ))}
      </table>
      <p />
      <p/>
      <label>{isMaterialVariant}</label>
      
      
      <p/>
      <table style={style}>
        <tr>
        <th style={style} width={"100px"}>
            TYPE
          </th>
          <th style={style} width={"200px"}>
            VARIANT
          </th>
          <th style={style} width={"200px"}>
            MATERIAL NAME
          </th>
          <th style={style} width={"200px"}>
            MATERIAL TYPE
          </th>
          <th style={style} width={"100px"}>
            SAVE SEPARATELY
          </th>
        </tr>
        {allMaterials &&
          allMaterials.map((item) => (
            <tr>
              <td style={style}>{item.type}</td>
              <td style={style}>{item.variant}</td>
              <td style={style}>{item.material.name}</td>
              <td style={style}>{item.material.type}</td>
              <td style={style}><input type="checkbox" disabled = {item.isVariant} defaultChecked={item.isSeparate} id={item.material.name} onChange={(e)=>saveSeparatelyHandler(e)}/></td>
            </tr>
          ))}
      </table>
      <p />
      <p />
      <p />
      {isVisible && <button onClick={() => downloadHandler()}>Download</button>}
    </div>
  );
}

export default App;
