import { useState } from "react";

const useUploadFile = () => {
    const [file, setFile] = useState();
    const [fileSize, setFileSize] = useState(0);

    function handleChange(event) {
        console.log(event.target.files[0])
        setFile(event.target.files[0]);
        const fileSize =
          event.target.files[0].size /* > 1000000
            ? Math.round((event.target.files[0].size / 1000000) * 10, 1) / 10 + "MB"
            : event.target.files[0].size > 1000
            ? Math.round((event.target.files[0].size / 1000) * 10, 1) / 10 + "KB"
            : Math.round(event.target.files[0].size * 10, 1) / 10 + "B"; */
        setFileSize(fileSize);

      }

      return {file, fileSize, handleChange}
}

export default useUploadFile