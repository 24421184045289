async function getTextureList (gltf) {
    const parser = gltf.parser
    const textureList = []
    if(parser.json.images){
    for (let i = 0; i < parser.json.images.length; i++) {
      textureList.push(parser.json.images[i])
    }
    const bufferPromises = parser.json.images.map((imageDef) => {
      return parser.getDependency('bufferView', imageDef.bufferView);
    });
    Promise.all(bufferPromises).then((buffers) => {
      console.log('BUFFERS', buffers); // Array<ArrayBuffer>
      
    });
  }
  return textureList
  }

  export default getTextureList