import * as THREE from "three";
import { v4 as uuidv4 } from "uuid";

async function buildMaterialHolders (allMaterials) { 
    
    const matBlobs = []
    const matHolderGroup = new THREE.Group()
    matHolderGroup.name = 'matHolderGroup'
    for (let i = 0; i < allMaterials.length; i++) {
      
        const geo = new THREE.SphereGeometry( 1, 1, 1 ); 
        const matHolder = new THREE.Mesh(geo, allMaterials[i].material)
        matHolder.name = allMaterials[i].name
        matHolder.material.userData.xid = uuidv4()
        console.log('matHolder', matHolder)
        matBlobs.push(matHolder)
    
    }
    
    return matBlobs
  }

  export default buildMaterialHolders