import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';


async function loadModel(model) {
    const url = URL.createObjectURL(model)

    const loader = new GLTFLoader();
    
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath( 'three/examples/jsm/libs/draco/' );
    loader.setDRACOLoader( dracoLoader );

    function modelLoader(model) {
      return new Promise((resolve, reject) => {
        
        loader.load(model, (data) => resolve(data), null, reject);
      });
    }
    const gltf = await modelLoader(url);
    return gltf;
  }

  export default loadModel