import * as THREE from "three";
import { v4 as uuidv4 } from "uuid";

async function addXid(model) {
    const xidList = [];

    //SET AXIS AS CENTER
    const box = new THREE.Box3().setFromObject(model.scene);
    const c = box.getCenter(new THREE.Vector3());
    const size = box.getSize(new THREE.Vector3());
    model.scene.position.set(-c.x, size.y / 2 - c.y, -c.z);

    model.scene.traverse(function (child) {
      if (!child.userData || !child.userData.xid) {
        child.userData.xid = uuidv4();
      }
      if (child.material) {
        if (!child.material.userData || !child.material.userData.xid) {
          child.material.userData.xid = uuidv4();
        }
      }

      xidList.push({
        name: child.name,
        type: child.type,
        xid: child.userData.xid,
        materialName:
          child.material && child.material.name ? child.material.name : "-NA-",
        materialXid:
          child.material &&
          child.material.userData &&
          child.material.userData.xid
            ? child.material.userData.xid
            : "-NA-",
      });
      
    });
    console.log(model);
    return {model, xidList};
  }


  export default addXid