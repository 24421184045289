import { GLTFExporter } from "three/examples/jsm/exporters/GLTFExporter.js";

const prepareExportFile = async (scene, clips) => {
    console.log(scene);
    console.log(clips)
    const exporter = new GLTFExporter();
    const glb = await new Promise((resolve, reject) =>
      exporter.parse([scene], resolve, reject, {
        animations: clips ? clips : [],
        binary: true,
        truncateDrawRange: false,
        includeCustomExtensions: true,
      })
    );
    return new Blob([glb], { type: "model/gltf-binary" });
  };

  export default prepareExportFile