import buildMaterialHolders from "./build_material_holders";
import downloadFile from "./download_file";
import prepareExportFile from "./prepare_export_file";

async function getNewGltf(allMaterials) {
   
      
      async function buildMaterialHoldersHandler (allMaterials) {
        
        const matBlobs = await buildMaterialHolders (allMaterials)
        for(let i = 0; i < matBlobs.length; i++) {
          console.log(matBlobs[i])
          const blob = await prepareExportFile(matBlobs[i], []);
          await downloadFile(blob, matBlobs[i].name+'.glb')
        }
        
        return matBlobs
      }
      const matHolder = buildMaterialHoldersHandler(allMaterials)
      
      return matHolder
   
    
  }

  export default getNewGltf