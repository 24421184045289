import { useEffect, useState } from "react";
import loadModel from "../util/load_model";
import addXid from "../util/add_xid";
import getTextureList from "../util/get_texture_list";
import getOriginalMaterialList from "../util/get_original_material_list";
import getVariantMaterialList from "../util/get_variant_material_list";

const useLoadGltf = (file) => {
    const [gltf, setGltf] = useState()
    const [xidList, setXidList] = useState();
    const [clips, setClips] = useState()
    const [textureList, setTextureList] = useState()
    const [allMaterials, setAllMaterials] = useState()

    useEffect(()=>{
        if (file) {
          console.log(file)
          async function getGltf(file) {
            const gltf = await loadModel(file);
            console.log(gltf)
            const {model, xidList} = await addXid(gltf);
            const textureList = await getTextureList(gltf)
            const originalMaterials = await getOriginalMaterialList(gltf)
            const variantMaterials = await getVariantMaterialList(gltf)
            setClips(gltf.animations)
            setAllMaterials([])
            return {model, xidList, textureList, originalMaterials, variantMaterials}
          }
          getGltf(file)
          .then(data=> {
            console.log(data)
            setGltf(data.model)
            setXidList(data.xidList)
            setTextureList(data.textureList)
            setAllMaterials(prevData => [...prevData, ...data.originalMaterials])
            setAllMaterials(prevData => [...prevData, ...data.variantMaterials])
        })
          
        }
      }, [file])
return { gltf, clips, xidList, textureList, allMaterials, setAllMaterials }
}

export default useLoadGltf