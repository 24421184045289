async function getOriginalMaterialList (gltf) {
    
    const originalMaterials = [];
  
    gltf.scene.traverse (function (child) {
      if(child.isMesh) {
        const isPresent = originalMaterials.find((item) => child.material.uuid === item.material.uuid);
        console.log(child.material.name, child.material.uuid)
        if(!isPresent) {
          originalMaterials.push({type: 'Original', name: child.material.name, variant: '-NA-', isVariant: false, material: child.material, isSeparate: true})
        }
      }
    })
    
    return originalMaterials
  }

  export default getOriginalMaterialList