import selectVariant from "./select_variant";

async function getVariantMaterialList(gltf) {
    
    const parser = gltf.parser;
   
  
    const variantsExtension =
    gltf.userData.gltfExtensions && gltf.userData.gltfExtensions["KHR_materials_variants"] ? gltf.userData.gltfExtensions["KHR_materials_variants"]: '';
    const variants = variantsExtension && variantsExtension.variants.map((variant) => variant.name);
    
    async function selectVariantFunction(scene, parser, variantsExtension, variants) {
      const variantMaterials = []
      if(variants){
        for await (const items of variants) {
          await selectVariant(
            scene,
            parser,
            variantsExtension,
            items,
            variantMaterials
          );
        }
      } 
      return variantMaterials
    }
    
    const variantMaterials = await selectVariantFunction(gltf.scene, parser, variantsExtension, variants)
    
    return variantMaterials ;
  }

  export default getVariantMaterialList