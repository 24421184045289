async function selectVariant(
    scene,
    parser,
    extension,
    variantName,
    variantMaterials
  ) {
    const variantMaterialList = []
    const variantIndex = extension.variants.findIndex((v) =>
      v.name.includes(variantName)
    );
    scene.traverse(async (object) => {
      if (!object.isMesh || !object.userData.gltfExtensions) return;
      const meshVariantDef =
        object.userData.gltfExtensions["KHR_materials_variants"];
      if (!meshVariantDef) return;
      if (!object.userData.originalMaterial) {
        const mat = object.material;
        console.log('variantMaterials', variantMaterials)
        console.log('mat object', object)
        const isPresent = variantMaterials.find((item) => item.material.uuid === mat.uuid);
        if (!isPresent) {
          console.log({type: 'Variant', variant: variantName, material: mat})
          //setAllMaterials(prevArray => [...prevArray,{type: 'Variant', variant: variantName,  isVariant: true, material: mat, isSeparate: true}])
            variantMaterialList.push({type: 'Variant', name: mat.name, variant: variantName,  isVariant: true, material: mat, isSeparate: true})
        }
      }
      const mapping = meshVariantDef.mappings.find((mapping) =>
        mapping.variants.includes(variantIndex)
      );
      if (mapping) {
        const mat = await parser.getDependency("material", mapping.material);
  
        if (mat.uuid) {
          const isPresent = variantMaterials.find((item) => item.material.uuid === mat.uuid);
          if (!isPresent) {
            console.log({type: 'Variant', variant: variantName, material: mat})
            
            //setAllMaterials(prevArray => [...prevArray, {type: 'Variant', variant: variantName, isVariant: true, material: mat, isSeparate: true}]);
            variantMaterialList.push({type: 'Variant', name: mat.name, variant: variantName,  isVariant: true, material: mat, isSeparate: true})
        }
        }
      } else {
        object.material = object.userData.originalMaterial;
      }
    });

    return variantMaterialList
  }

  export default selectVariant